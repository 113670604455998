<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Resumen del proveedor" :dense="true" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: $store.state.suppliers.retrieveLoader, text: instance.name }" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn @click="dialogConfirmLock=true" outlined :ripple="false" v-if="($helpers.hasSomePermission(['view_suppliergroup']) && $store?.state?.auth?.account?.suppliergroup_feature)">
          <v-icon left>mdi-lock{{ instance?.group_details?.name === 'Bloqueados' ? '-open' : '' }}</v-icon> {{ instance?.group_details?.name !== 'Bloqueados' ? 'Bloquear' : 'Desbloquear' }} proveedor
        </v-btn>
        <v-btn class="ml-2" @click="dialogSupplier = true" color="primary" :ripple="false">
          <v-icon left>mdi-pencil</v-icon> Editar proveedor
        </v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="pa-0" style="z-index: 0;">
      <skeleton-detail v-if="loading" />
      <div class="d-flex" v-else>
        <PermissionDenied v-if="!$helpers.hasPermission(['view_supplier'])" />
        <template v-else>
          <!-- detail card -->
          <div class="mr-6" style="min-width: 400px;">
            <div :style="$store?.state?.auth?.account?.suppliergroup_feature ? 'position:fixed!important;' : ''">
              <v-alert text outlined class="inf-alert-error" color="red" rounded dense :width="400" v-if="instance?.group_details?.name === 'Bloqueados'">
                <div class="d-flex pa-0 align-center">
                  <v-icon color="red">mdi-lock</v-icon>
                  <div class="ml-4 alert-description">
                    <span class="body-1 font-weight-bold red--text">Proveedor bloqueado</span>
                    <span class="d-block body-2 pb-1 red--text" style="line-height: 1.3">
                      Los documentos de este proveedor son reclamados automáticamente desde el
                      <span class="font-weight-medium">{{ instance?.group_assigned_date | dateTimeSecond}}</span>
                    </span>
                  </div>
                </div>
              </v-alert>
              <v-card class="primary" width="400">
                <v-card-text>
                  <span class="subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 90%;">
                    {{instance.name}}
                  </span>
                  <span class="d-block subtitle-2 white--text">{{instance.tax_id}}</span>
                  <div class="mt-4">
                    <span class="d-block body-1 white--text">Actividad económica</span>
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold" v-if="instance?.tax_settings?.cl_activity_description">{{instance?.tax_settings?.cl_activity_description}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </span>
                  </div>
                  <div class="mt-4">
                    <span class="d-block body-1 white--text">Dirección</span>
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold" v-if="instance?.address">{{instance.address}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </span>
                  </div>
                  <div class="mt-4">
                    <span class="d-block body-1 white--text">Comuna</span>
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold" v-if="instance?.state">{{instance.state}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </span>
                  </div>
                  <div class="mt-4">
                    <span class="d-block body-1 white--text">Moneda</span>
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold" v-if="instance?.currency">{{instance.currency}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div class="mt-4">
                      <span class="d-block body-1 white--text">Creado el</span>
                      <span class="subtitle-2 white--text">
                        <span class="font-weight-bold" v-if="instance?.created">{{instance.created | dateTimeSecond}}</span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </span>
                    </div>
                    <div class="mt-4">
                      <span class="d-block body-1 white--text">Actualizado el</span>
                      <span class="subtitle-2 white--text">
                        <span class="font-weight-bold" v-if="instance?.updated">{{instance.updated | dateTimeSecond}}</span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" flat v-if="$store?.state?.auth?.account?.suppliergroup_feature">
                <v-toolbar color="transparent" flat height="46">
                  <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-bold d-flex align-center">
                    <v-icon size="18" left color="secondary">mdi-folder</v-icon>
                    Grupo
                  </v-toolbar-title>
                </v-toolbar>
                <v-divider />

                <v-card-text class="py-2 px-5">
                  <v-list class="v-list-form-main transparent pa-0" v-if="($helpers.hasSomePermission(['view_suppliergroup']) && $store?.state?.auth?.account?.suppliergroup_feature)">
                    <v-list-item class="pa-0">
                      <v-list-item-icon class="mr-4">
                        <v-avatar color="primary" :size="28">
                          <v-icon color="white" :size="12">mdi-folder</v-icon>
                        </v-avatar>
                      </v-list-item-icon>
                      <v-avatar v-if="$store.state.suppliers.retrieveLoader" tile class="placeholder rounded-pill" height="15" width="300" />
                      <v-list-item-title class="secondary--text body-1 font-weight-semibold d-inline-block text-truncate" v-else style="max-width: 90%;">
                        <v-chip v-if="instance.group_details?.name === 'Bloqueados'" color="greyMedium" class="font-weight-semibold" small label>Bloqueados<v-icon right>mdi-lock</v-icon></v-chip>
                        <template v-else>{{ instance?.group_details?.name ?? 'Sin grupo asociado'}}</template>
                        </v-list-item-title>
                      <v-list-item-action>
                        <v-menu v-if="$helpers.hasSomePermission(['add_suppliergroup'])" close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-n4" :disabled="['Bloqueados'].includes(instance?.group_details?.name)" v-bind="attrs" v-on="on" text x-small><v-icon color="secondary" size="24">mdi-dots-vertical</v-icon></v-btn>
                          </template>
                          <v-list class="pa-0">
                            <v-list-item class="px-3" v-if="!!instance?.group_details?.name" @click="dialogConfirmDestroyGroup=true" :ripple="false">
                              <v-list-item-title class="body-2 d-flex align-center">
                                <v-icon color="secondary" size="16" left>mdi-folder-minus</v-icon>Eliminar del grupo
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" v-else @click="dialogAddGroup=true" :ripple="false">
                              <v-list-item-title class="body-2 d-flex align-center">
                                <v-icon color="secondary" size="16" left>mdi-folder-minus</v-icon>Agregar a grupo
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" flat v-if="!instance.action">
                <v-toolbar color="transparent" flat height="46">
                  <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-bold d-flex align-center">
                    <v-icon size="18" left color="secondary">mdi-flash</v-icon>
                    Automatización
                  </v-toolbar-title>
                </v-toolbar>
                <v-divider />

                <v-card-text class="pt-3 pb-5 px-5">
                  <v-list class="v-list-form-main transparent pa-0" v-if="($helpers.hasSomePermission(['view_suppliergroup']) && $store?.state?.auth?.account?.suppliergroup_feature)">
                    <v-list-item class="pa-0">
                      <v-list-item-title class="secondary--text body-1 font-weight-semibold d-inline-block text-truncate" style="max-width: 90%;">
                        <span class="body-2 text--disabled font-italic">Sin especificar</span>
                        <!-- <template v-else>{{ instance?.group_details?.name ?? 'Sin grupo asociado'}}</template> -->
                        </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <!-- <v-chip color="greyMedium" class="font-weight-semibold" small label>Bloqueados<v-icon right>mdi-lock</v-icon></v-chip> -->
                </v-card-text>
              </v-card>
            </div>
          </div>
          <!-- end summary -->

          <!-- panels -->
          <div class="mb-8" style="flex:1; min-width: 200px;">
            <!-- contacts -->
            <v-expansion-panels v-model="contactPanel">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-5 py-4">
                  <v-row no-gutters align="center">
                    <span class="ml-n1 subtitle-2 overlay--text font-weight-bold"><v-icon class="mr-2" small color="overlay">mdi-account</v-icon>Contactos</span>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider />
                  <v-skeleton-loader class="rounded-md" v-if="contactsLoading" transition="fade-transition" type="table-tbody" />
                  <template v-else>
                    <v-empty-state class="mt-5 pb-2" v-if="!contactsList.length" type="customer-econ" id="documentos" customClass="mt-5" :isFree="true" title="Aún no tienes contactos" :height="139" />
                    <v-simple-table class="table-not-rounded no-hover pt-0 pb-1 px-2 transparent" v-else dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left overlay--text font-weight-medium" style="min-width: 180px;"><v-icon class="ml-n2" small left color="overlay">mdi-account</v-icon>Nombre</th>
                            <th class="text-left overlay--text font-weight-medium" style="min-width: 180px;"><v-icon small left color="overlay">mdi-email</v-icon>Email</th>
                            <th style="min-width: 50px;" class="text-right overlay--text font-weight-medium"><v-icon small left color="overlay">mdi-phone</v-icon>Teléfono</th>
                            <th style="min-width: 60px;"/>
                          </tr>
                          <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -8px" />
                        </thead>
                        <tbody>
                          <tr v-for="contact in contactsList" :key="contact.id">
                            <td class="body-2">
                              <span class="d-inline-block text-truncate ml-n1" v-if="contact.name" style="max-width: 100%;">{{contact.name}}</span>
                              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                            </td>
                            <td class="text-left body-2" style="min-width: 180px;">
                              <span class="d-inline-block text-truncate" style="max-width: 100%;">{{contact.email}}</span>
                            </td>
                            <td class="text-right body-2">
                              <span v-if="contact.phone">{{contact.phone}}</span>
                              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                            </td>
                            <td class="text-right body-2">
                              <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10" transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="grey-300--text" size="24">mdi-dots-vertical</v-icon></v-btn>
                                </template>
                                <v-list class="pa-0">
                                  <v-list-item class="px-3" @click="destroy(contact.id)" :ripple="false">
                                    <v-list-item-title class="body-2"><v-icon color="overlay" size="20" left>mdi-delete</v-icon>Eliminar contacto</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end contacts -->

          <!-- related documents -->
          <v-card  class="mt-5" flat>
            <v-card-text class="pa-0">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="px-5 py-4">
                    <span class="subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-file-document-multiple</v-icon>Documentos recibidos</span>
                  </v-col>
                </v-row>
                <v-divider />
                <purchase-list :loading="purchaseLoading" :show-pagination="false" />
                <template v-if="count">
                  <v-divider />
                  <v-row class="px-4 py-4" align="center">
                    <v-btn class="ml-5 body-1" :to="{name: 'PurchaseList', query: { supplier: instance.id, ...(instance.name && { name: instance.name })} }" :ripple="false" text color="primary">Ver todos los documentos</v-btn>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
            <!-- end related documents -->

            <!-- related documents -->
            <v-card flat class="mt-5" v-if="$store?.state?.auth?.account?.auto_purchase_sii">
              <v-card-text class="pa-0">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col class="px-5 py-4">
                      <span class="subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-file-document-multiple</v-icon>Documentos pendientes de recibir</span>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <purchase-pending-list :loading="siiLoading" :show-pagination="false" />
                  <template v-if="count">
                    <v-divider />
                    <v-row class="px-4 py-4" align="center">
                      <v-btn class="ml-5 body-1" :to="{name: 'PurchaseList', query: { supplier: instance.id, ...(instance.name && { name: instance.name })} }" :ripple="false" text color="primary">Ver todos los documentos</v-btn>
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>
              <!-- end related documents -->
          </div>
          <!-- end panels -->
        </template>
      </div>
    </v-col>

    <!-- dialog confirm lock -->
    <v-dialog v-model="dialogConfirmLock" width="838" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 secondary--secondary font-weight-semibold">{{ instance?.group_details?.name !==
              'Bloqueados' ? 'Bloquear' : 'Desbloquear' }} proveedor</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirmLock = false" icon small retain-focus-on-click><v-icon color="secondary" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 secondary--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-el-lock.svg`)" :width="178" />
            </v-col>
            <v-col class="pr-4 body-1 pl-6">
              <template v-if="instance?.group_details?.name !== 'Bloqueados'">
                Al bloquear el proveedor todos los nuevos documentos serán reclamadas automáticamente.

                <span class="d-block body-1 pt-3">Puedes desbloquear al proveedor y asignarlo a cualquier grupo cuando lo desees.</span>
              </template>
              <span class="d-block body-1 pt-3" v-else>Al desbloquear el proveedor no tendrá grupos asociados y estará sujeto a las reglas de automatización para proveedores sin grupos. Podrá ser asignado a otros grupos en el futuro.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para
                continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="dialogConfirmLock = false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialogConfirmLock = false, toggleBlock()" :loading="$store.state.irs.createLoader"
            :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm lock -->

    <!-- dialog confirm destroy group -->
    <v-dialog v-model="dialogConfirmDestroyGroup" width="838" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 secondary--secondary font-weight-semibold">Eliminar del grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirmDestroyGroup = false" icon small retain-focus-on-click><v-icon color="secondary" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 secondary--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-el-lock.svg`)" :width="178" />
            </v-col>
            <v-col class="pr-4 body-1 pl-6">
              Al eliminar del grupo, el proveedores no tendrá grupos asociados y estará sujetos a las reglas de automatización para proveedores sin grupos.

              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para
                continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="dialogConfirmDestroyGroup = false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialogConfirmDestroyGroup = false, destroySuppliertGroup()" :loading="$store.state.irs.createLoader"
            :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm destroy group -->

    <!-- dialog add to group -->
    <v-dialog v-model="dialogAddGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">Agregar a grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogAddGroup=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-7 pt-5 px-5" style="max-height: 600px !important;">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3" class="pt-2">
              <img :src="require(`@/assets/backgrounds/modal-el-lock.svg`)" :width="160" />
            </v-col>
            <v-col class="pl-12 pr-0">
              <v-list class="pa-0 mt-5 transparent v-list-form-extended">
                <span class="d-block">Grupos</span>
                <v-list-item class="py-1">
                  <v-list-item-title>
                    <v-col class="pa-0">
                      <v-select
                        v-model="group"
                        hide-details
                        single-line
                        color="primary"
                        dense
                        outlined
                        :items="groupsList"
                        :chips="false"
                        item-text="name"
                        item-value="id"
                        placeholder="Selecciona el grupo"
                        no-data-text="No existen grupos para mostrar"
                      />
                    </v-col>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogAddGroup=false">Cancelar</v-btn>
          <v-btn color="primary" @click="addToGroup" :loading="createGroupLoading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog add to group -->

    <!--modal update supplier-->
    <v-dialog v-model="dialogSupplier" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">Editar proveedor</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogSupplier=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-0 overflow-y-auto" style="max-height: 710px;" min-height="700" scrollable>
          <v-row class="px-5" no-gutters>
            <v-col cols="3" class="pa-0">
              <span class="secondary--text body-1">{{$t('generals.RUT')}}</span>
              <v-text-field v-model="editable.tax_id" readonly disabled v-dni="$store?.state?.auth?.account?.country" outlined single-line dense hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="secondary--text body-1">Nombre del proveedor</span>
              <v-text-field v-model.trim="$v.editable.name.$model" :error="$v.editable.name.$error" outlined single-line dense maxlength="100" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 py-3" no-gutters>
            <v-col cols="12" class="pa-0">
              <span class="secondary--text body-1">Actividad económica</span>
              <v-text-field v-model="editable.tax_settings.cl_activity_description" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 pb-0" no-gutters>
            <v-col cols="8" class="pa-0">
              <span class="secondary--text body-1">Dirección</span>
              <v-text-field v-model.trim="$v.editable.address.$model" :error="$v.editable.address.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="secondary--text body-1">{{$t('generals.Comuna')}}</span>
              <v-autocomplete v-model="$v.editable.state.$model" :error="$v.editable.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                <template v-slot:item="data">
                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 secondary--text font-weight-semibold">
              Contactos
              <v-chip class="ml-2 pa-2 font-weight-regular" x-small color="grey-background">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in contacts" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <div class="col-4 pa-0">
                  <span class="secondary--text body-1">Nombre</span>
                  <v-text-field v-model.trim="d.name" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-4 py-0 px-1">
                  <span class="secondary--text body-1">Email</span>
                  <v-text-field v-model="$v.contacts.$each[i].email.$model" :error="$v.contacts.$each[i].email.$error" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-3 pa-0" style="max-width: 290px;">
                  <span class="secondary--text body-1">Teléfono</span>
                  <v-text-field v-model="d.phone" v-mask="`${d.phone} # #### ####`" maxlength="16" outlined required single-line dense hide-details />
                </div>
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-5" v-if="i >= 1 || contacts.length > 1" @click="destroy(d.id), contacts.splice(i, 1)" text color="overlay" :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn class="mt-1" v-if="contacts.length < 7" @click.prevent="addRowContacts" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otro</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSupplier=false">Cancelar</v-btn>
          <v-btn color="primary" :loading="$store.state.suppliers.createLoader" @click="updateSupplier">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end modal update supplier -->
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import VEmptyState from '@/components/commons/VEmptyState'
  import MiniHeader from '@/components/commons/MiniHeader'
  import cloneDeep from 'lodash/cloneDeep'
  import { rutValidator } from 'vue-dni'
  import { required, maxLength, requiredIf, email } from 'vuelidate/lib/validators'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import RenderPdf from '@/components/files/RenderPdf'
  import PurchaseList from '@/modules/purchase/components/PurchaseList'
  import PurchasePendingList from '@/modules/purchase/pending-receive/components/PendingReceiveList'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import PermissionDenied from '@/modules/http/views/PermissionDenied'
  // import DocumentTypes from '@/collections/documentTypes'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
    components: {
      ChipStatus,
      VModal,
      SkeletonDetail,
      RenderPdf,
      PurchaseList,
      MiniHeader,
      PermissionDenied,
      PurchasePendingList,
      VEmptyState
    },
  data: () => ({
    contactPanel: 0,
    contactsLoading: false,
    group: null,
    dialogSupplier: false,
    contacts: [{ name: '', email: '', phone: '' }],
    dialogAddGroup: false,
    createGroupLoading: false,
    dialogConfirmLock: false,
    dialogConfirmDestroyGroup: false,
    confirm: '',
    scroll: 0,
    comments: '',
    dialogSwap: false,
    loading: false,
    panelIrsSii: [],
    panelComments: false,
    panelEmail: false,
    panelPDF: false,
    panelRelatedDocuments: 0,
    isAccept: false,
    purchaseLoading: false,
    siiLoading: false,
    dialogConfirm: false,
    currentList: [],
    current: {},
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Proveedores',
          route: 'SupplierListCreate'
        }
      ]
    },
    editable: {
      name: '',
      tax_id: '',
      address: '',
      tax_settings: {
        cl_activity_description: ''
      },
      contacts: [],
      country: 'CL',
      state: ''
    }
  }),
  computed: mapState({
    statesList: state => state.suppliers.statesList,
    count: state => state.purchases.purchasesCount,
    purchasesList: state => state.purchases.purchasesList,
    instance: state => state.suppliers.suppliersDetail,
    groupsList: state => state.suppliers.groupsList,
    contactsList: state => state.suppliers.contactsList
  }),
  created () {
    this.getList()
    if (this.$helpers.hasPermission(['view_purchase'])) {
      this.purchaseLoading = true
      this.$store.dispatch('purchases/LIST', {
        resource: 'purchases',
        query: {
          supplier: this.$route.params.id,
          page_size: 6
        }
      })
      .finally(() => {
        this.purchaseLoading = false
      })
      this.siiLoading = true
      this.$store.dispatch('purchases/LIST', {
        resource: 'purchases_from_sii',
        query: {
          supplier: this.$route.params.id,
          page_size: 6
        }
      })
      .finally(() => {
        this.siiLoading = false
      })
    }
    if (this.$helpers.hasSomePermission(['view_suppliergroup'])) {
      this.$store.dispatch('suppliers/LIST', {
      resource: 'suppliers/groups',
      query: {
          page_size: 9999
        }
      })
    }
  },
  methods: {
    addRowContacts () {
      this.contacts.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    getList () {
      this.loading = true
      this.$store.dispatch('suppliers/RETRIEVE', {
        resource: 'suppliers',
        id: this.$route.params.id
      })
      .then(() => {
        const supplier = cloneDeep(this.instance)
        this.editable = supplier
        this.getContacts()
      })
      .finally(() => {
        this.loading = false
      })
    },
    clearInput () {
      this.$v.editable.$reset()
      this.$v.contacts.$reset()
      this.editable = {
        name: '',
        tax_id: '',
        tax_settings: {
          cl_activity_description: ''
        },
        country: null,
        state: '',
        address: '',
        group: null
      }
      this.contacts = [{ name: '', email: '', phone: '' }]
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    async addToGroup () {
      this.createGroupLoading = true
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.instance.id,
        payload: {
          group: this.group
        }
      })
      .then(() => {
        this.$dialog.message.info('Los proveedores han sido asignados correctamente al grupo.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.group = null
        this.dialogAddGroup = false
        this.createGroupLoading = false
      })
    },
    toggleBlock () {
      this.$store.dispatch('suppliers/CREATE', {
        resource: `suppliers/${this.$route.params.id}/toggle_block`
      })
      .then(() => {
        this.getList()
        this.$dialog.message.info(`El proveedor ha sido ${this.instance.group.name === 'Bloqueados' ? 'desbloqueado' : 'bloqueado'} con éxito`)
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.confirm = ''
      })
    },
    destroySuppliertGroup () {
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.$route.params.id,
        payload: {
          group: null
        }
      })
      .then(() => {
        this.getList()
        this.$dialog.message.info('El proveedor se ha eliminado del grupo con éxito')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.confirm = ''
      })
    },
    getContacts () {
      this.contactsLoading = true
        this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/contacts',
          query: {
            supplier: this.$route.params.id
        }
      })
      .then((response) => {
        if (response.data.results.length) {
          this.contacts = cloneDeep(response.data.results)
        }
      })
      .finally(() => {
        this.contactsLoading = false
      })
    },
    updateSupplier () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
      const payload = {
        name: this.editable.name,
        state: this.editable.state.id || this.editable.state,
        address: this.editable.address,
        tax_id: this.editable.tax_id,
        tax_settings: this.editable.tax_settingss,
        country: this.$store?.state?.auth?.account?.country
      }
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.$route.params.id,
        payload: payload
      })
      .then(async (response) => {
        await Promise.all(this.contacts.map(async (item) => {
        if (item?.id) {
          this.$store.dispatch('suppliers/UPDATE', {
            resource: 'suppliers/contacts',
            id: item.id,
            payload: {...item, ...{ supplier: response.data.id }}
          })
        } else if (item.email.length || item.name.length || item.phone.length) {
          this.$store.dispatch('suppliers/CREATE', {
            resource: 'suppliers/contacts',
            payload: {...item, ...{ supplier: response.data.id }}
          })
        }
      }))
        this.$dialog.message.info('El proveedor ha sido actualizado')
      })
      .catch(() => {
        this.$dialog.message.error('Error al actualizar el proveedor.')
      })
      .finally(() => {
        this.dialogSupplier = false
      })
    }
  },
  validations: {
    editable: {
      name: { required, maxLength: maxLength(100) },
      country: { required },
      state: { required },
      address: { },
      tax_id: {
        required,
        format: function (val) {
          return rutValidator(val)
        },
        maxLength: maxLength(16)
      },
      tax_settings: {
        cl_activity_description: {
          required: requiredIf(function () {
            return this.editable.country === 'CL'
          }),
          maxLength: maxLength(64)
        }
      }
    },
    contacts: {
      $each: {
        email: {
         required,
          email,
          maxLength: maxLength(64)
        }
      }
    }
  }
}
</script>
<style scoped>
.v-list--three-line .v-list-item .v-list-item__subtitle, .v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 2 !important;
}
.v-list--three-line .v-list-item, .v-list-item--three-line {
  min-height: auto;
}
</style>